import { api } from "@/services/api"
import { SongRoot, SongHistoryRoot } from "@/types"

export const getSonglistHistory = async (channel: string) => {
  const data = await api.get<SongHistoryRoot>(
    `/v1/streamers/${channel}/playHistory?size=30000`
  )
  return data
}

export const getSongs = async (channel: string) => {
  const data = await api.get<SongRoot>(
    `/v1/streamers/${channel}/songs/export`
  )
  return data
}
