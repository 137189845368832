import axios from 'axios'

const baseURL = "https://api.streamersonglist.com" as string

export const api = axios.create({
    baseURL,
    timeout: 20000,
})

api.interceptors.response.use(
    (response) => response,
    (error) => {
        return Promise.reject(error)
    }
)